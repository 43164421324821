import * as React from "react"
import { IconOptions } from "../../../../types"
const SvgComponent = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={292}
    height={292}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 19.87 19.87"
    {...props}
  >
    <defs>
      <style>{".teddralogoinverted{fill:#34519d}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <circle
        cx={9.93}
        cy={9.93}
        r={9.93}
        style={{
          fill: "#fefefe",
        }}
      />
      <g id="_1823664167760">
        <path
          d="M13.31 3.63c1.9 0 3.35 1.28 3.35 3.18 0 1.89-1.89 3.12-3.79 3.12-1.9 0-3.09-1.23-3.09-3.12 0-1.9 1.63-3.18 3.53-3.18zm2.02 1.16c-.37.09-.97.27-1.06.45-.12.25-.54.45-.54.45s.32.07.13.27c-.18.2.37.46-.13.35-.5-.11-.2.36.02.47.21.1.57 0 .62-.15.04-.16.24-.72 1.04-.27.25.14.06.26.03.36-.05.2-.57.29-.81.2-.37-.11-1.07.61-.38 1.14.7.53 1.04-.02.83.72-.04.12.55-.16.82-.61.14-.37.21-.77.21-1.2 0-.83-.29-1.58-.78-2.18zm-2.92-.3s0 .7.11.9c.11.19.08.77-.35.96-.42.2-.42.31-.21.5.14.14.14.43-.07.42-.11-.01-.44-.25-.5-.3-.18-.13-.55.4-.15.59.39.2.96-.09 1.05.68.09.77.06.96.09 1.18.02.21-.66-.04-1.04-.29-.58-.61-.94-1.44-.94-2.36 0-.82.29-1.58.78-2.17l1.23-.11zM7.28 8.68c-.15-.36.59-.1.93-.51l.47-.93c-.26-1.19.53-1.21-.11-1.75-1.93-1.62-3.11-.65-3.66.77-.08.2-.02.48.02.67.01.07-.31.33-.42.81-.34 1.42.55 2.08.79 1.66.2-.37-.21-2.03-.16-1.97.31.4.86 1.04.97 1.09.53.21.01.78.02.76l-1.25 1.84-1.24 1.42.74.42-1.17 2.48 1.19.57.17-.29-.37-.55 1.19-1.56.61.08-.48 2.55 1.45-.01-.03-.26-.53-.31.67-1.75 1.1-.11-.25-2.96 1.85-2.08c-.26-.31-.02-.09-.35-.32-.38.3-.76.6-1.13.9l-.19.09-.43-.03c.01 0-.24-.36-.4-.72z"
          className="teddralogoinverted"
        />
      </g>
    </g>
  </svg>
)
export default SvgComponent
